import React from 'react'
import CONNECTED_TO_CATS_EYES_BRACELET_AF
    from '../../Files/CATS EYES BEDTIME STORIES/1_CONNECTED TO CATS-EYES BRACELET_AF.txt';
import CONNECTED_TO_CATS_EYES_BRACELET_CN
    from '../../Files/CATS EYES BEDTIME STORIES/1_CONNECTED TO CATS-EYES BRACELET_CN.txt';
import CONNECTED_TO_CATS_EYES_BRACELET_DE
    from '../../Files/CATS EYES BEDTIME STORIES/1_CONNECTED TO CATS-EYES BRACELET_DE.txt';
import CONNECTED_TO_CATS_EYES_BRACELET_EN
    from '../../Files/CATS EYES BEDTIME STORIES/1_CONNECTED TO CATS-EYES BRACELET_EN.txt';
import CONNECTED_TO_CATS_EYES_BRACELET_ES
    from '../../Files/CATS EYES BEDTIME STORIES/1_CONNECTED TO CATS-EYES BRACELET_ES.txt';
import CONNECTED_TO_CATS_EYES_BRACELET_FA
    from '../../Files/CATS EYES BEDTIME STORIES/1_CONNECTED TO CATS-EYES BRACELET_FA.txt';
import CONNECTED_TO_CATS_EYES_BRACELET_FR
    from '../../Files/CATS EYES BEDTIME STORIES/1_CONNECTED TO CATS-EYES BRACELET_FR.txt';
import CONNECTED_TO_CATS_EYES_BRACELET_IN
    from '../../Files/CATS EYES BEDTIME STORIES/1_CONNECTED TO CATS-EYES BRACELET_IN.txt';
import CONNECTED_TO_CATS_EYES_BRACELET_JP
    from '../../Files/CATS EYES BEDTIME STORIES/1_CONNECTED TO CATS-EYES BRACELET_JP.txt';
import CONNECTED_TO_CATS_EYES_BRACELET_RU
    from '../../Files/CATS EYES BEDTIME STORIES/1_CONNECTED TO CATS-EYES BRACELET_RU.txt';
import CONNECTED_TO_CATS_EYES_BRACELET_SE
    from '../../Files/CATS EYES BEDTIME STORIES/1_CONNECTED TO CATS-EYES BRACELET_SE.txt';
import CONNECTED_TO_CATS_EYES_BRACELET_SO
    from '../../Files/CATS EYES BEDTIME STORIES/1_CONNECTED TO CATS-EYES BRACELET_SO.txt';
import CONNECTED_TO_CATS_EYES_BRACELET_TI
    from '../../Files/CATS EYES BEDTIME STORIES/1_CONNECTED TO CATS-EYES BRACELET_TI.txt';
import CONNECTED_TO_CATS_EYES_BRACELET_UAE
    from '../../Files/CATS EYES BEDTIME STORIES/1_CONNECTED TO CATS-EYES BRACELET_UAE.txt';
import The_inner_voice_AF from '../../Files/CATS EYES BEDTIME STORIES/2_The inner voice_AF.txt';
import The_inner_voice_CN from '../../Files/CATS EYES BEDTIME STORIES/2_The inner voice_CN.txt';
import The_inner_voice_DE from '../../Files/CATS EYES BEDTIME STORIES/2_The inner voice_DE.txt';
import The_inner_voice_EN from '../../Files/CATS EYES BEDTIME STORIES/2_The inner voice_EN.txt';
import The_inner_voice_ES from '../../Files/CATS EYES BEDTIME STORIES/2_The inner voice_ES.txt';
import The_inner_voice_FA from '../../Files/CATS EYES BEDTIME STORIES/2_The inner voice_FA.txt';
import The_inner_voice_FR from '../../Files/CATS EYES BEDTIME STORIES/2_The inner voice_FR.txt';
import The_inner_voice_IN from '../../Files/CATS EYES BEDTIME STORIES/2_The inner voice_IN.txt';
import The_inner_voice_JP from '../../Files/CATS EYES BEDTIME STORIES/2_The inner voice_JP.txt';
import The_inner_voice_RU from '../../Files/CATS EYES BEDTIME STORIES/2_The inner voice_RU.txt';
import The_inner_voice_SE from '../../Files/CATS EYES BEDTIME STORIES/2_The inner voice_SE.txt';
import The_inner_voice_SO from '../../Files/CATS EYES BEDTIME STORIES/2_The inner voice_SO.txt';
import The_inner_voice_TI from '../../Files/CATS EYES BEDTIME STORIES/2_The inner voice_TI.txt';
import The_inner_voice_UAE from '../../Files/CATS EYES BEDTIME STORIES/2_The inner voice_UAE.txt';
import Heaven_and_hell_AF from '../../Files/CATS EYES BEDTIME STORIES/3_Heaven and hell_AF.txt';
import Heaven_and_hell_CN from '../../Files/CATS EYES BEDTIME STORIES/3_Heaven and hell_CN.txt';
import Heaven_and_hell_DE from '../../Files/CATS EYES BEDTIME STORIES/3_Heaven and hell_DE.txt';
import Heaven_and_hell_EN from '../../Files/CATS EYES BEDTIME STORIES/3_Heaven and hell_EN.txt';
import Heaven_and_hell_ES from '../../Files/CATS EYES BEDTIME STORIES/3_Heaven and hell_ES.txt';
import Heaven_and_hell_FA from '../../Files/CATS EYES BEDTIME STORIES/3_Heaven and hell_FA .txt';
import Heaven_and_hell_FR from '../../Files/CATS EYES BEDTIME STORIES/3_Heaven and hell_FR.txt';
import Heaven_and_hell_IN from '../../Files/CATS EYES BEDTIME STORIES/3_Heaven and hell_IN.txt';
import Heaven_and_hell_JP from '../../Files/CATS EYES BEDTIME STORIES/3_Heaven and hell_JP.txt';
import Heaven_and_hell_RU from '../../Files/CATS EYES BEDTIME STORIES/3_Heaven and hell_RU.txt';
import Heaven_and_hell_SE from '../../Files/CATS EYES BEDTIME STORIES/3_Heaven and hell_SE.txt';
import Heaven_and_hell_SO from '../../Files/CATS EYES BEDTIME STORIES/3_Heaven and hell_SO.txt';
import Heaven_and_hell_TI from '../../Files/CATS EYES BEDTIME STORIES/3_Heaven and hell_TI.txt';
import Heaven_and_hell_UAE from '../../Files/CATS EYES BEDTIME STORIES/3_Heaven and hell_UAE.txt';
import Standing_up_on_the_inside_AF from '../../Files/CATS EYES BEDTIME STORIES/4_Standing up on the inside_AF.txt';
import Standing_up_on_the_inside_CN from '../../Files/CATS EYES BEDTIME STORIES/4_Standing up on the inside_CN.txt';
import Standing_up_on_the_inside_DE from '../../Files/CATS EYES BEDTIME STORIES/4_Standing up on the inside_DE.txt';
import Standing_up_on_the_inside_EN from '../../Files/CATS EYES BEDTIME STORIES/4_Standing up on the inside_EN.txt';
import Standing_up_on_the_inside_ES from '../../Files/CATS EYES BEDTIME STORIES/4_Standing up on the inside_ES.txt';
import Standing_up_on_the_inside_FA from '../../Files/CATS EYES BEDTIME STORIES/4_Standing up on the inside_FA.txt';
import Standing_up_on_the_inside_FR from '../../Files/CATS EYES BEDTIME STORIES/4_Standing up on the inside_FR.txt';
import Standing_up_on_the_inside_IN from '../../Files/CATS EYES BEDTIME STORIES/4_Standing up on the inside_IN.txt';
import Standing_up_on_the_inside_JP from '../../Files/CATS EYES BEDTIME STORIES/4_Standing up on the inside_JP.txt';
import Standing_up_on_the_inside_RU from '../../Files/CATS EYES BEDTIME STORIES/4_Standing up on the inside_RU.txt';
import Standing_up_on_the_inside_SE from '../../Files/CATS EYES BEDTIME STORIES/4_Standing up on the inside_SE.txt';
import Standing_up_on_the_inside_SO from '../../Files/CATS EYES BEDTIME STORIES/4_Standing up on the inside_SO.txt';
import Standing_up_on_the_inside_TI from '../../Files/CATS EYES BEDTIME STORIES/4_Standing up on the inside_TI.txt';
import Standing_up_on_the_inside_UAE from '../../Files/CATS EYES BEDTIME STORIES/4_Standing up on the inside_UAE.txt';
import The_Cracked_pot_AF from '../../Files/CATS EYES BEDTIME STORIES/5_The Cracked pot_AF.txt';
import The_Cracked_pot_CN from '../../Files/CATS EYES BEDTIME STORIES/5_The Cracked pot_CN.txt';
import The_Cracked_pot_DE from '../../Files/CATS EYES BEDTIME STORIES/5_The Cracked pot_DE.txt';
import The_Cracked_pot_EN from '../../Files/CATS EYES BEDTIME STORIES/5_The Cracked pot_EN.txt';
import The_Cracked_pot_ES from '../../Files/CATS EYES BEDTIME STORIES/5_The Cracked pot_ES.txt';
import The_Cracked_pot_FA from '../../Files/CATS EYES BEDTIME STORIES/5_The Cracked pot_FA.txt';
import The_Cracked_pot_FR from '../../Files/CATS EYES BEDTIME STORIES/5_The Cracked pot_FR.txt';
import The_Cracked_pot_IN from '../../Files/CATS EYES BEDTIME STORIES/5_The Cracked pot_IN.txt';
import The_Cracked_pot_JP from '../../Files/CATS EYES BEDTIME STORIES/5_The Cracked pot_JP.txt';
import The_Cracked_pot_RU from '../../Files/CATS EYES BEDTIME STORIES/5_The Cracked pot_RU.txt';
import The_Cracked_pot_SE from '../../Files/CATS EYES BEDTIME STORIES/5_The Cracked pot_SE.txt';
import The_Cracked_pot_SO from '../../Files/CATS EYES BEDTIME STORIES/5_The Cracked pot_SO.txt';
import The_Cracked_pot_TI from '../../Files/CATS EYES BEDTIME STORIES/5_The Cracked pot_TI.txt';
import The_Cracked_pot_UAE from '../../Files/CATS EYES BEDTIME STORIES/5_The Cracked pot_UAE.txt';
import School_of_the_animals_AF from '../../Files/CATS EYES BEDTIME STORIES/6_School of the animals_AF.txt';
import School_of_the_animals_CN from '../../Files/CATS EYES BEDTIME STORIES/6_School of the animals_CN.txt';
import School_of_the_animals_DE from '../../Files/CATS EYES BEDTIME STORIES/6_School of the animals_DE.txt';
import School_of_the_animals_EN from '../../Files/CATS EYES BEDTIME STORIES/6_School of the animals_EN.txt';
import School_of_the_animals_ES from '../../Files/CATS EYES BEDTIME STORIES/6_School of the animals_ES.txt';
import School_of_the_animals_FA from '../../Files/CATS EYES BEDTIME STORIES/6_School of the animals_FA.txt';
import School_of_the_animals_FR from '../../Files/CATS EYES BEDTIME STORIES/6_School of the animals_FR.txt';
import School_of_the_animals_IN from '../../Files/CATS EYES BEDTIME STORIES/6_School of the animals_IN.txt';
import School_of_the_animals_JP from '../../Files/CATS EYES BEDTIME STORIES/6_School of the animals_JP.txt';
import School_of_the_animals_RU from '../../Files/CATS EYES BEDTIME STORIES/6_School of the animals_RU.txt';
import School_of_the_animals_SE from '../../Files/CATS EYES BEDTIME STORIES/6_School of the animals_SE.txt';
import School_of_the_animals_SO from '../../Files/CATS EYES BEDTIME STORIES/6_School of the animals_SO.txt';
import School_of_the_animals_TI from '../../Files/CATS EYES BEDTIME STORIES/6_School of the animals_TI.txt';
import School_of_the_animals_UAE from '../../Files/CATS EYES BEDTIME STORIES/6_School of the animals_UAE.txt';
import Peace_of_mind_AF from '../../Files/CATS EYES BEDTIME STORIES/7_Peace of mind_AF.txt';
import Peace_of_mind_CN from '../../Files/CATS EYES BEDTIME STORIES/7_Peace of mind_CN.txt';
import Peace_of_mind_DE from '../../Files/CATS EYES BEDTIME STORIES/7_Peace of mind_DE.txt';
import Peace_of_mind_EN from '../../Files/CATS EYES BEDTIME STORIES/7_Peace of mind_EN.txt';
import Peace_of_mind_ES from '../../Files/CATS EYES BEDTIME STORIES/7_Peace of mind_ES.txt';
import Peace_of_mind_FA from '../../Files/CATS EYES BEDTIME STORIES/7_Peace of mind_FA.txt';
import Peace_of_mind_FR from '../../Files/CATS EYES BEDTIME STORIES/7_Peace of mind_FR.txt';
import Peace_of_mind_IN from '../../Files/CATS EYES BEDTIME STORIES/7_Peace of mind_IN.txt';
import Peace_of_mind_JP from '../../Files/CATS EYES BEDTIME STORIES/7_Peace of mind_JP.txt';
import Peace_of_mind_RU from '../../Files/CATS EYES BEDTIME STORIES/7_Peace of mind_RU.txt';
import Peace_of_mind_SE from '../../Files/CATS EYES BEDTIME STORIES/7_Peace of mind_SE.txt';
import Peace_of_mind_SO from '../../Files/CATS EYES BEDTIME STORIES/7_Peace of mind_SO.txt';
import Peace_of_mind_TI from '../../Files/CATS EYES BEDTIME STORIES/7_Peace of mind_TI.txt';
import Peace_of_mind_UAE from '../../Files/CATS EYES BEDTIME STORIES/7_Peace of mind_UAE.txt';
import The_final_lesson_AF from '../../Files/CATS EYES BEDTIME STORIES/8_The final lesson_AF.txt';
import The_final_lesson_CN from '../../Files/CATS EYES BEDTIME STORIES/8_The final lesson_CN.txt';
import The_final_lesson_DE from '../../Files/CATS EYES BEDTIME STORIES/8_The final lesson_DE.txt';
import The_final_lesson_EN from '../../Files/CATS EYES BEDTIME STORIES/8_The final lesson_EN.txt';
import The_final_lesson_ES from '../../Files/CATS EYES BEDTIME STORIES/8_The final lesson_ES.txt';
import The_final_lesson_FA from '../../Files/CATS EYES BEDTIME STORIES/8_The final lesson_FA.txt';
import The_final_lesson_FR from '../../Files/CATS EYES BEDTIME STORIES/8_The final lesson_FR.txt';
import The_final_lesson_IN from '../../Files/CATS EYES BEDTIME STORIES/8_The final lesson_IN.txt';
import The_final_lesson_JP from '../../Files/CATS EYES BEDTIME STORIES/8_The final lesson_JP.txt';
import The_final_lesson_RU from '../../Files/CATS EYES BEDTIME STORIES/8_The final lesson_RU.txt';
import The_final_lesson_SE from '../../Files/CATS EYES BEDTIME STORIES/8_The final lesson_SE.txt';
import The_final_lesson_SO from '../../Files/CATS EYES BEDTIME STORIES/8_The final lesson_SO.txt';
import The_final_lesson_TI from '../../Files/CATS EYES BEDTIME STORIES/8_The final lesson_TI.txt';
import The_final_lesson_UAE from '../../Files/CATS EYES BEDTIME STORIES/8_The final lesson_UAE.txt';
import I_wish_you_enough_AF from '../../Files/CATS EYES BEDTIME STORIES/9_I wish you enough_AF.txt';
import I_wish_you_enough_CN from '../../Files/CATS EYES BEDTIME STORIES/9_I wish you enough_CN.txt';
import I_wish_you_enough_DE from '../../Files/CATS EYES BEDTIME STORIES/9_I wish you enough_DE.txt';
import I_wish_you_enough_EN from '../../Files/CATS EYES BEDTIME STORIES/9_I wish you enough_EN.txt';
import I_wish_you_enough_ES from '../../Files/CATS EYES BEDTIME STORIES/9_I wish you enough_ES.txt';
import I_wish_you_enough_FA from '../../Files/CATS EYES BEDTIME STORIES/9_I wish you enough_FA.txt';
import I_wish_you_enough_FR from '../../Files/CATS EYES BEDTIME STORIES/9_I wish you enough_FR.txt';
import I_wish_you_enough_IN from '../../Files/CATS EYES BEDTIME STORIES/9_I wish you enough_IN.txt';
import I_wish_you_enough_JP from '../../Files/CATS EYES BEDTIME STORIES/9_I wish you enough_JP.txt';
import I_wish_you_enough_RU from '../../Files/CATS EYES BEDTIME STORIES/9_I wish you enough_RU.txt';
import I_wish_you_enough_SE from '../../Files/CATS EYES BEDTIME STORIES/9_I wish you enough_SE.txt';
import I_wish_you_enough_SO from '../../Files/CATS EYES BEDTIME STORIES/9_I wish you enough_SO.txt';
import I_wish_you_enough_TI from '../../Files/CATS EYES BEDTIME STORIES/9_I wish you enough_TI.txt';
import I_wish_you_enough_UAE from '../../Files/CATS EYES BEDTIME STORIES/9_I wish you enough_UAE.txt';
import The_broken_mirror_AF from '../../Files/CATS EYES BEDTIME STORIES/10_The broken mirror_AF.txt';
import The_broken_mirror_CN from '../../Files/CATS EYES BEDTIME STORIES/10_The broken mirror_CN.txt';
import The_broken_mirror_DE from '../../Files/CATS EYES BEDTIME STORIES/10_The broken mirror_DE.txt';
import The_broken_mirror_EN from '../../Files/CATS EYES BEDTIME STORIES/10_The broken mirror_EN.txt';
import The_broken_mirror_ES from '../../Files/CATS EYES BEDTIME STORIES/10_The broken mirror_ES.txt';
import The_broken_mirror_FA from '../../Files/CATS EYES BEDTIME STORIES/10_The broken mirror_FA.txt';
import The_broken_mirror_FR from '../../Files/CATS EYES BEDTIME STORIES/10_The broken mirror_FR.txt';
import The_broken_mirror_IN from '../../Files/CATS EYES BEDTIME STORIES/10_The broken mirror_IN.txt';
import The_broken_mirror_JP from '../../Files/CATS EYES BEDTIME STORIES/10_The broken mirror_JP.txt';
import The_broken_mirror_RU from '../../Files/CATS EYES BEDTIME STORIES/10_The broken mirror_RU.txt';
import The_broken_mirror_SE from '../../Files/CATS EYES BEDTIME STORIES/10_The broken mirror_SE.txt';
import The_broken_mirror_SO from '../../Files/CATS EYES BEDTIME STORIES/10_The broken mirror_SO.txt';
import The_broken_mirror_TI from '../../Files/CATS EYES BEDTIME STORIES/10_The broken mirror_TI.txt';
import The_broken_mirror_UAE from '../../Files/CATS EYES BEDTIME STORIES/10_The broken mirror_UAE.txt';
import CATS_EYES_Image_one from '../../images/Illustrationer/1_How the wild cat got his eyes.jpg';
import CATS_EYES_Image_two from '../../images/Illustrationer/2_the inner voice.jpg';
import CATS_EYES_Image_tree from '../../images/Illustrationer/3_Heaven and hell.jpg';
import CATS_EYES_Image_four from '../../images/Illustrationer/4_Standing up on the inside.jpg';
import CATS_EYES_Image_five from '../../images/Illustrationer/5_The cracked pot.jpg';
import CATS_EYES_Image_six from '../../images/Illustrationer/6_School of animals.jpg';
import CATS_EYES_Image_seven from '../../images/Illustrationer/7_Peace of mind.jpg';
import CATS_EYES_Image_eight from '../../images/Illustrationer/8_The final lesson.jpg'
import CATS_EYES_Image_nine from '../../images/Illustrationer/9_I wish you enough.jpg';
import CATS_EYES_Image_ten from '../../images/Illustrationer/10_The broken mirror.jpg';
import CONNECTED_TO_CATS_EYES_BRACELET_SE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats Eyes 01-SVENSKA-mixdown.mp3';
import CONNECTED_TO_CATS_EYES_BRACELET_EN_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats Eyes 01_ENGELSKA-mixdown.mp3';
import CONNECTED_TO_CATS_EYES_BRACELET_FR_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats Eyes 01_FRANSKA-mixdown.mp3';
import CONNECTED_TO_CATS_EYES_BRACELET_ES_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats Eyes 01_SPANSKA-mixdown.mp3';
import CONNECTED_TO_CATS_EYES_BRACELET_DE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats Eyes 01_TYSKA-mixdown.mp3';
import The_inner_voice_SE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 02_SVENSKA-mixdown.mp3';
import The_inner_voice_EN_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 02_ENGELSKA-mixdown.mp3';
import The_inner_voice_FR_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 02_FRANSKA-mixdown.mp3';
import The_inner_voice_ES_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 02_SPANSKA-mixdown.mp3';
import The_inner_voice_DE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 02_TYSKA-mixdown.mp3';
import Heaven_and_hell_SE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 03_SVENSKA-mixdown.mp3';
import Heaven_and_hell_EN_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 03_ENGELSKA-mixdown.mp3';
import Heaven_and_hell_FR_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 03_FRANSKA-mixdown.mp3';
import Heaven_and_hell_ES_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 03_SPANSKA-mixdown.mp3';
import Heaven_and_hell_DE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 03_TYSKA-mixdown.mp3';
import Standing_up_on_the_inside_SE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 04_SVENSKA-mixdown-ny.mp3';
import Standing_up_on_the_inside_EN_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 04_ENGELSKA-mixdown.mp3';
import Standing_up_on_the_inside_FR_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 04_FRANSKA-mixdown.mp3';
import Standing_up_on_the_inside_ES_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 04_SPANSKA-mixdown.mp3';
import Standing_up_on_the_inside_DE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 04_TYSKA-mixdown.mp3';
import The_Cracked_pot_SE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 05_SVENSKA-mixdown.mp3';
import The_Cracked_pot_EN_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 05_ENGELSKA-mixdown.mp3';
import The_Cracked_pot_FR_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 05_FRANSKA-mixdown.mp3';
import The_Cracked_pot_ES_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 05_SPANSKA-mixdown.mp3';
import The_Cracked_pot_DE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 05_TYSKA-mixdown.mp3';
import School_of_the_animals_SE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 06_SVENSKA-mixdown.mp3';
import School_of_the_animals_EN_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 06_ENGELSKA-mixdown.mp3';
import School_of_the_animals_FR_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 06_FRANSKA-mixdown.mp3';
import School_of_the_animals_ES_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 06_SPANSKA-mixdown.mp3';
import School_of_the_animals_DE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 06_TYSKA-mixdown.mp3';
import Peace_of_mind_SE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 07_SVENSKA-mixdown.mp3';
import Peace_of_mind_EN_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 07_ENGELSKA-mixdown.mp3';
import Peace_of_mind_FR_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 07_FRANSKA-mixdown.mp3';
import Peace_of_mind_ES_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 07_SPANSKA-mixdown.mp3';
import Peace_of_mind_DE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 07_TYSKA-mixdown.mp3';
import The_final_lesson_SE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 08_SVENSKA-mixdown.mp3';
import The_final_lesson_EN_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 08_ENGELSKA-mixdown.mp3';
import The_final_lesson_FR_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 08_FRANSKA-mixdown.mp3';
import The_final_lesson_ES_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 08_SPANSKA-mixdown.mp3';
import The_final_lesson_DE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 08_TYSKA-mixdown.mp3';
import I_wish_you_enough_SE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 09_SVENSKA-mixdown.mp3';
import I_wish_you_enough_EN_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats Eyes 09_ENGELSKA-mixdown.mp3';
import I_wish_you_enough_FR_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats Eyes 09_FRANSKA-mixdown.mp3';
import I_wish_you_enough_ES_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats Eyes 09_SPANSKA-mixdown.mp3';
import I_wish_you_enough_DE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats Eyes 09_TYSKA-mixdown.mp3';
import The_broken_mirror_SE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 10_SVENSKA-mixdown.mp3';
import The_broken_mirror_EN_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats Eyes 10_ENGELSKA-mixdown.mp3';
import The_broken_mirror_FR_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats Eyes 10_FRANSKA-mixdown.mp3';
import The_broken_mirror_ES_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats Eyes 10_SPANSKA-mixdown.mp3';
import The_broken_mirror_DE_SOUND from '../../Files/Cats Eyes Stories-mixade/Cats eyes 10_TYSKA-mixdown.mp3';


export default class Text_list extends React.Component {

    putListToObj() {
        let text_image_sound = [
            {
                "څنګه وحشیانه پيشو سترګې ترلاسه کړې": {
                    text: CONNECTED_TO_CATS_EYES_BRACELET_AF,
                    image: CATS_EYES_Image_one
                }
            },
            {
                '这只大猫是如何得到他的眼睛的': {
                    text: CONNECTED_TO_CATS_EYES_BRACELET_CN,
                    image: CATS_EYES_Image_one
                }
            },
            {
                'die Wildkatze seine Augen bekam': {
                    text: CONNECTED_TO_CATS_EYES_BRACELET_DE,
                    image: CATS_EYES_Image_one,
                    sound: CONNECTED_TO_CATS_EYES_BRACELET_DE_SOUND
                }
            },
            {
                'How the wild cat got his eyes': {
                    text: CONNECTED_TO_CATS_EYES_BRACELET_EN,
                    image: CATS_EYES_Image_one,
                    sound: CONNECTED_TO_CATS_EYES_BRACELET_EN_SOUND
                }
            },
            {
                'Cómo obtuvo el gato silvestre sus ojos': {
                    text: CONNECTED_TO_CATS_EYES_BRACELET_ES,
                    image: CATS_EYES_Image_one,
                    sound: CONNECTED_TO_CATS_EYES_BRACELET_ES_SOUND
                }
            },
            {
                'چگونه گربه وحشی چشمانش را پیدا کرد': {

                    text: CONNECTED_TO_CATS_EYES_BRACELET_FA,
                    image: CATS_EYES_Image_one
                }
            },
            {
                'Comment le chat sauvage a eu ses yeux': {
                    text: CONNECTED_TO_CATS_EYES_BRACELET_FR,
                    image: CATS_EYES_Image_one,
                    sound: CONNECTED_TO_CATS_EYES_BRACELET_FR_SOUND
                }
            },
            {
                'जंगली बिल्ली ने अपनी आंखे कैसे पायीं।': {
                    text: CONNECTED_TO_CATS_EYES_BRACELET_IN,
                    image: CATS_EYES_Image_one
                }

            },
            {
                'どのように野生の猫は彼の目を得ました': {
                    text: CONNECTED_TO_CATS_EYES_BRACELET_JP,
                    image: CATS_EYES_Image_one
                }
            },
            {
                'Как Дикий Кот получил свои глаза': {
                    text: CONNECTED_TO_CATS_EYES_BRACELET_RU,
                    image: CATS_EYES_Image_one
                }
            },
            {
                'Hur den vilda katten fick ögonen': {
                    text: CONNECTED_TO_CATS_EYES_BRACELET_SE,
                    image: CATS_EYES_Image_one,
                    sound: CONNECTED_TO_CATS_EYES_BRACELET_SE_SOUND
                }
            },
            {
                'Side bay bisada duurjoogta ahi uga helaysaa  indhaheeda': {
                    text: CONNECTED_TO_CATS_EYES_BRACELET_SO,
                    image: CATS_EYES_Image_one
                }
            },
            {
                'እቲ ናይ በረኻ ድሙ ከመይ አዒንቱ ከምዝረኸበ ።': {
                    text: CONNECTED_TO_CATS_EYES_BRACELET_TI,
                    image: CATS_EYES_Image_one
                }
            },
            {
                'كيف حصلت القطة البرية على عينيها': {
                    text: CONNECTED_TO_CATS_EYES_BRACELET_UAE,
                    image: CATS_EYES_Image_one
                }
            },
            {
                "داخلي غږ": {
                    text: The_inner_voice_AF,
                    image: CATS_EYES_Image_two
                }
            },
            {
                '内心的声音': {
                    text: The_inner_voice_CN,
                    image: CATS_EYES_Image_two
                }
            },
            {
                'Die innere Stimme': {
                    text: The_inner_voice_DE,
                    image: CATS_EYES_Image_two,
                    sound: The_inner_voice_DE_SOUND
                }
            },
            {
                'The inner voice': {
                    text: The_inner_voice_EN,
                    image: CATS_EYES_Image_two,
                    sound: The_inner_voice_EN_SOUND
                }
            },
            {
                'La voz interna': {
                    text: The_inner_voice_ES,
                    image: CATS_EYES_Image_two,
                    sound: The_inner_voice_ES_SOUND
                }
            },
            {
                'صدای درون': {
                    text: The_inner_voice_FA,
                    image: CATS_EYES_Image_two
                }
            },
            {
                'La voix intérieure': {
                    text: The_inner_voice_FR,
                    image: CATS_EYES_Image_two,
                    sound: The_inner_voice_FR_SOUND
                }
            },
            {
                'अन्तर्मन की आवाज़ ': {
                    text: The_inner_voice_IN,
                    image: CATS_EYES_Image_two
                }
            },
            {
                '内なる声': {
                    text: The_inner_voice_JP,
                    image: CATS_EYES_Image_two
                }
            },
            {
                'Внутренний голос': {
                    text: The_inner_voice_RU,
                    image: CATS_EYES_Image_two
                }
            },
            {
                'Den inre rösten': {
                    text: The_inner_voice_SE,
                    image: CATS_EYES_Image_two,
                    sound: The_inner_voice_SE_SOUND
                }
            },
            {
                'Codkii hoose': {
                    text: The_inner_voice_SO,
                    image: CATS_EYES_Image_two
                }
            },
            {
                'ውሽጣዊ ድምፂ': {
                    text: The_inner_voice_TI,
                    image: CATS_EYES_Image_two
                }
            },
            {
                'الصوت الداخلى': {
                    text: The_inner_voice_UAE,
                    image: CATS_EYES_Image_two
                }
            },
            {
                'جنت او دوزخ': {
                    text: Heaven_and_hell_AF,
                    image: CATS_EYES_Image_tree
                }
            },
            {
                '天堂与地狱': {
                    text: Heaven_and_hell_CN,
                    image: CATS_EYES_Image_tree
                }
            },
            {
                'Himmel und Hölle': {
                    text: Heaven_and_hell_DE,
                    image: CATS_EYES_Image_tree,
                    sound: Heaven_and_hell_DE_SOUND
                }
            },
            {
                'Heaven and hell': {
                    text: Heaven_and_hell_EN,
                    image: CATS_EYES_Image_tree,
                    sound: Heaven_and_hell_EN_SOUND
                }
            },
            {
                'El cielo y el infierno': {
                    text: Heaven_and_hell_ES,
                    image: CATS_EYES_Image_tree,
                    sound: Heaven_and_hell_ES_SOUND
                }
            },
            {
                'بهشت و جهنم': {
                    text: Heaven_and_hell_FA,
                    image: CATS_EYES_Image_tree
                }
            },
            {
                'Paradis et enfer': {
                    text: Heaven_and_hell_FR,
                    image: CATS_EYES_Image_tree,
                    sound: Heaven_and_hell_FR_SOUND
                }
            },
            {
                'स्वर्ग और नर्क': {
                    text: Heaven_and_hell_IN,
                    image: CATS_EYES_Image_tree
                }
            },
            {
                '天と地獄': {
                    text: Heaven_and_hell_JP,
                    image: CATS_EYES_Image_tree
                }
            },
            {
                'Рай и ад': {
                    text: Heaven_and_hell_RU,
                    image: CATS_EYES_Image_tree
                }
            },
            {
                'Himlen och helvetet': {
                    text: Heaven_and_hell_SE,
                    image: CATS_EYES_Image_tree,
                    sound: Heaven_and_hell_SE_SOUND
                }
            },
            {
                'JANO IYO CADAABTII': {
                    text: Heaven_and_hell_SO,
                    image: CATS_EYES_Image_tree
                }
            },
            {
                'ገነትን ሲኦልን': {
                    text: Heaven_and_hell_TI,
                    image: CATS_EYES_Image_tree
                }
            },
            {
                'النعيم و الجحيم': {
                    text: Heaven_and_hell_UAE,
                    image: CATS_EYES_Image_tree
                }
            },
            {
                'دننه باندې پاڅه اودرېدل': {
                    text: Standing_up_on_the_inside_AF,
                    image: CATS_EYES_Image_four
                }
            },
            {
                '是内心的力量让人强大起来': {
                    text: Standing_up_on_the_inside_CN,
                    image: CATS_EYES_Image_four
                }
            },
            {
                'Ich stehe auf der Innenseite': {
                    text: Standing_up_on_the_inside_DE,
                    image: CATS_EYES_Image_four,
                    sound: Standing_up_on_the_inside_DE_SOUND
                }
            },
            {
                'Standing up on the inside': {
                    text: Standing_up_on_the_inside_EN,
                    image: CATS_EYES_Image_four,
                    sound: Standing_up_on_the_inside_EN_SOUND
                }
            },
            {
                'De pie en el interior': {
                    text: Standing_up_on_the_inside_ES,
                    image: CATS_EYES_Image_four,
                    sound: Standing_up_on_the_inside_ES_SOUND
                }
            },
            {
                'کنار ایستادن': {
                    text: Standing_up_on_the_inside_FA,
                    image: CATS_EYES_Image_four
                }
            },
            {
                "Debout à l'intérieur": {
                    text: Standing_up_on_the_inside_FR,
                    image: CATS_EYES_Image_four,
                    sound: Standing_up_on_the_inside_FR_SOUND
                }
            },
            {
                'अंदर से मजबूत होना': {
                    text: Standing_up_on_the_inside_IN,
                    image: CATS_EYES_Image_four
                }
            },
            {
                '内側に立っ': {
                    text: Standing_up_on_the_inside_JP,
                    image: CATS_EYES_Image_four

                }
            },
            {
                'Опирайся на стержень внутри себя': {
                    text: Standing_up_on_the_inside_RU,
                    image: CATS_EYES_Image_four
                }
            },
            {
                'Stå upp på insidan': {
                    text: Standing_up_on_the_inside_SE,
                    image: CATS_EYES_Image_four,
                    sound: Standing_up_on_the_inside_SE_SOUND
                }
            },
            {
                'Gudaha istaag': {
                    text: Standing_up_on_the_inside_SO,
                    image: CATS_EYES_Image_four
                }
            },
            {
                'ኣብ ውሽጢ ጠጠው ምባል': {
                    text: Standing_up_on_the_inside_TI,
                    image: CATS_EYES_Image_four

                }
            },
            {
                'الوقوف من الداخل': {
                    text: Standing_up_on_the_inside_UAE,
                    image: CATS_EYES_Image_four
                }
            },
            {
                'چټک برتن': {
                    text: The_Cracked_pot_AF,
                    image: CATS_EYES_Image_five
                }
            },
            {
                '有裂缝的罐子': {
                    text: The_Cracked_pot_CN,
                    image: CATS_EYES_Image_five
                }
            },
            {
                'Der Cracked Pot': {
                    text: The_Cracked_pot_DE,
                    image: CATS_EYES_Image_five,
                    sound: The_Cracked_pot_DE_SOUND
                }
            },
            {
                'The Cracked pot': {
                    text: The_Cracked_pot_EN,
                    image: CATS_EYES_Image_five,
                    sound: The_Cracked_pot_EN_SOUND
                }
            },
            {
                'La vasija agrietada': {
                    text: The_Cracked_pot_ES,
                    image: CATS_EYES_Image_five,
                    sound: The_Cracked_pot_ES_SOUND
                }
            },
            {
                'گلدان شکسته': {
                    text: The_Cracked_pot_FA,
                    image: CATS_EYES_Image_five
                }
            },
            {
                'Le pot craquelé': {
                    text: The_Cracked_pot_FR,
                    image: CATS_EYES_Image_five,
                    sound: The_Cracked_pot_FR_SOUND
                }
            },
            {
                "टूटा हुआ बर्तन": {
                    text: The_Cracked_pot_IN,
                    image: CATS_EYES_Image_five
                }
            },
            {
                'ひびの入った鍋': {
                    text: The_Cracked_pot_JP,
                    image: CATS_EYES_Image_five
                }
            },
            {
                'Треснувший горшок': {
                    text: The_Cracked_pot_RU,
                    image: CATS_EYES_Image_five
                }
            },
            {
                'Den spruckna krukan': {
                    text: The_Cracked_pot_SE,
                    image: CATS_EYES_Image_five,
                    sound: The_Cracked_pot_SE_SOUND
                }
            },
            {
                'Dherigii dilaacay': {
                    text: The_Cracked_pot_SO,
                    image: CATS_EYES_Image_five
                }
            },
            {
                'እቲ ዝነቕዐ ዕትሮ': {
                    text: The_Cracked_pot_TI,
                    image: CATS_EYES_Image_five

                }

            },
            {
                'الإناء المتصدع': {
                    text: The_Cracked_pot_UAE,
                    image: CATS_EYES_Image_five
                }
            },
            {
                'د څارویو ښوونځی': {
                    text: School_of_the_animals_AF,
                    image: CATS_EYES_Image_six
                }
            },
            {
                '动物学校': {
                    text: School_of_the_animals_CN,
                    image: CATS_EYES_Image_six
                }
            },
            {
                'Schule der Tiere': {
                    text: School_of_the_animals_DE,
                    image: CATS_EYES_Image_six,
                    sound: School_of_the_animals_DE_SOUND
                }
            },
            {
                'School of the animals': {
                    text: School_of_the_animals_EN,
                    image: CATS_EYES_Image_six,
                    sound: School_of_the_animals_EN_SOUND
                }
            },
            {
                'Escuela de animales': {
                    text: School_of_the_animals_ES,
                    image: CATS_EYES_Image_six,
                    sound: School_of_the_animals_ES_SOUND
                }
            },
            {
                'مدرسه حیوانات': {
                    text: School_of_the_animals_FA,
                    image: CATS_EYES_Image_six
                }
            },
            {
                'École des animaux': {
                    text: School_of_the_animals_FR,
                    image: CATS_EYES_Image_six,
                    sound: School_of_the_animals_FR_SOUND
                }
            },
            {
                'जानवरों का विद्यालय': {
                    text: School_of_the_animals_IN,
                    image: CATS_EYES_Image_six
                }
            },
            {
                '動物の学校': {
                    text: School_of_the_animals_JP,
                    image: CATS_EYES_Image_six
                }
            },
            {
                'Школа животных': {
                    text: School_of_the_animals_RU,
                    image: CATS_EYES_Image_six
                }
            },
            {
                'Djurens skola': {
                    text: School_of_the_animals_SE,
                    image: CATS_EYES_Image_six,
                    sound: School_of_the_animals_SE_SOUND
                }
            },
            {
                'Iskuulka xayawaanka': {
                    text: School_of_the_animals_SO,
                    image: CATS_EYES_Image_six
                }
            },
            {
                'ቤት ትምህርቲ እንስሳት': {
                    text: School_of_the_animals_TI,
                    image: CATS_EYES_Image_six
                }
            },
            {
                'مدرسة الحيوانات': {
                    text: School_of_the_animals_UAE,
                    image: CATS_EYES_Image_six
                }
            },
            {
                'ذهن سکون': {
                    text: Peace_of_mind_AF,
                    image: CATS_EYES_Image_seven
                }
            },
            {
                '心灵的安宁': {
                    text: Peace_of_mind_CN,
                    image: CATS_EYES_Image_seven
                }
            },
            {
                'Seelenfrieden': {
                    text: Peace_of_mind_DE,
                    image: CATS_EYES_Image_seven,
                    sound: Peace_of_mind_DE_SOUND
                }
            },
            {
                'Peace of mind': {
                    text: Peace_of_mind_EN,
                    image: CATS_EYES_Image_seven,
                    sound: Peace_of_mind_EN_SOUND
                }
            },
            {
                'Paz interior': {
                    text: Peace_of_mind_ES,
                    image: CATS_EYES_Image_seven,
                    sound: Peace_of_mind_ES_SOUND
                }
            },
            {
                'آرامش ذهن': {
                    text: Peace_of_mind_FA,
                    image: CATS_EYES_Image_seven
                }
            },
            {
                "Tranquillité d'esprit": {
                    text: Peace_of_mind_FR,
                    image: CATS_EYES_Image_seven,
                    sound: Peace_of_mind_FR_SOUND
                }
            },
            {
                'मन की शांति': {
                    text: Peace_of_mind_IN,
                    image: CATS_EYES_Image_seven
                }
            },
            {
                '心の平和': {
                    text: Peace_of_mind_JP,
                    image: CATS_EYES_Image_seven
                }
            },
            {
                'Спокойствие в душе': {
                    text: Peace_of_mind_RU,
                    image: CATS_EYES_Image_seven
                }
            },
            {
                'Sinnesro': {
                    text: Peace_of_mind_SE,
                    image: CATS_EYES_Image_seven,
                    sound: Peace_of_mind_SE_SOUND

                }
            },
            {
                'IN LAY S NABAD SIIYO': {
                    text: Peace_of_mind_SO,
                    image: CATS_EYES_Image_seven
                }
            },
            {
                'ናይ ኣእምሮ ዕረፍቲ': {
                    text: Peace_of_mind_TI,
                    image: CATS_EYES_Image_seven
                }
            },
            {
                'راحة البال': {
                    text: Peace_of_mind_UAE,
                    image: CATS_EYES_Image_seven
                }
            },
            {
                'وروستۍ لوست': {
                    text: The_final_lesson_AF,
                    image: CATS_EYES_Image_eight
                }
            },
            {
                '最后一课': {
                    text: The_final_lesson_CN,
                    image: CATS_EYES_Image_eight
                }
            },
            {
                'Die letzte Lektion': {
                    text: The_final_lesson_DE,
                    image: CATS_EYES_Image_eight,
                    sound: The_final_lesson_DE_SOUND
                }
            },
            {
                'The final lesson': {
                    text: The_final_lesson_EN,
                    image: CATS_EYES_Image_eight,
                    sound: The_final_lesson_EN_SOUND
                }
            },
            {
                'La lección final': {
                    text: The_final_lesson_ES,
                    image: CATS_EYES_Image_eight,
                    sound: The_final_lesson_ES_SOUND
                }
            },
            {
                'درس آخر': {
                    text: The_final_lesson_FA,
                    image: CATS_EYES_Image_eight
                }
            },
            {
                'La leçon finale': {
                    text: The_final_lesson_FR,
                    image: CATS_EYES_Image_eight,
                    sound: The_final_lesson_FR_SOUND
                }
            },
            {
                'अंतिम सीख': {
                    text: The_final_lesson_IN,
                    image: CATS_EYES_Image_eight
                }
            },
            {
                '最後のレッスン': {
                    text: The_final_lesson_JP,
                    image: CATS_EYES_Image_eight
                }
            },
            {
                'Последний урок': {
                    text: The_final_lesson_RU,
                    image: CATS_EYES_Image_eight
                }
            },
            {
                'Den sista lektionen': {
                    text: The_final_lesson_SE,
                    image: CATS_EYES_Image_eight,
                    sound: The_final_lesson_SE_SOUND
                }
            },
            {
                'Casharkii Ugu danbeeyay': {
                    text: The_final_lesson_SO,
                    image: CATS_EYES_Image_eight
                }
            },
            {
                'ናይ መወዳእታ  ትምህርቲ': {
                    text: The_final_lesson_TI,
                    image: CATS_EYES_Image_eight
                }
            },
            {
                'الدرس الأخير': {
                    text: The_final_lesson_UAE,
                    image: CATS_EYES_Image_eight
                }
            },
            {
                'تاسو پوره غوښتل زما خواهش دې': {
                    text: I_wish_you_enough_AF,
                    image: CATS_EYES_Image_nine
                }
            },
            {
                '我愿你拥有足够的': {
                    text: I_wish_you_enough_CN,
                    image: CATS_EYES_Image_nine
                }
            },
            {
                'Ich wünsche dir genug': {
                    text: I_wish_you_enough_DE,
                    image: CATS_EYES_Image_nine,
                    sound: I_wish_you_enough_DE_SOUND
                }
            },
            {
                'I wish you enough': {
                    text: I_wish_you_enough_EN,
                    image: CATS_EYES_Image_nine,
                    sound: I_wish_you_enough_EN_SOUND
                }
            },
            {
                'Te deseo lo suficiente': {
                    text: I_wish_you_enough_ES,
                    image: CATS_EYES_Image_nine,
                    sound: I_wish_you_enough_ES_SOUND
                }
            },
            {
                'تو را همیشه آرزو کرده ام': {
                    text: I_wish_you_enough_FA,
                    image: CATS_EYES_Image_nine
                }
            },
            {
                'Je vous souhaite assez': {
                    text: I_wish_you_enough_FR,
                    image: CATS_EYES_Image_nine,
                    sound: I_wish_you_enough_FR_SOUND
                }
            },
            {
                'मैं तुम्हारे लिए प्रार्थना करता रहूँगा': {
                    text: I_wish_you_enough_IN,
                    image: CATS_EYES_Image_nine
                }
            },
            {
                '私は十分にあなたを願っています': {
                    text: I_wish_you_enough_JP,
                    image: CATS_EYES_Image_nine
                }
            },
            {
                'Я желаю тебе достаточно всего': {
                    text: I_wish_you_enough_RU,
                    image: CATS_EYES_Image_nine
                }
            },
            {
                'Jag önskar dig tillräckligt': {
                    text: I_wish_you_enough_SE,
                    image: CATS_EYES_Image_nine,
                    sound: I_wish_you_enough_SE_SOUND
                }
            },
            {
                'Waxaan rajaynayaa Inay nagu filan tahay': {
                    text: I_wish_you_enough_SO,
                    image: CATS_EYES_Image_nine
                }
            },
            {
                'ክንዲ ዝአክል ይምነየልካ': {
                    text: I_wish_you_enough_TI,
                    image: CATS_EYES_Image_nine
                }
            },
            {
                'أتمنى لك ما يكفى': {
                    text: I_wish_you_enough_UAE,
                    image: CATS_EYES_Image_nine
                }
            },
            {
                'مات شوی عکس': {
                    text: The_broken_mirror_AF,
                    image: CATS_EYES_Image_ten
                }
            },
            {
                '破碎的镜子': {
                    text: The_broken_mirror_CN,
                    image: CATS_EYES_Image_ten
                }
            },
            {
                'Der zerbrochene Spiegel': {
                    text: The_broken_mirror_DE,
                    image: CATS_EYES_Image_ten,
                    sound: The_broken_mirror_DE_SOUND
                }
            },
            {
                'The broken mirror': {
                    text: The_broken_mirror_EN,
                    image: CATS_EYES_Image_ten,
                    sound: The_broken_mirror_EN_SOUND
                }
            },
            {
                'El espejo roto': {
                    text: The_broken_mirror_ES,
                    image: CATS_EYES_Image_ten,
                    sound: The_broken_mirror_ES_SOUND
                }
            },
            {
                'آیینه شکسته': {
                    text: The_broken_mirror_FA,
                    image: CATS_EYES_Image_ten
                }
            },
            {
                'Le miroir brisé': {
                    text: The_broken_mirror_FR,
                    image: CATS_EYES_Image_ten,
                    sound: The_broken_mirror_FR_SOUND
                }
            },
            {
                'टूटा हुआ आईना': {
                    text: The_broken_mirror_IN,
                    image: CATS_EYES_Image_ten
                }
            },
            {
                '壊れたミラー': {
                    text: The_broken_mirror_JP,
                    image: CATS_EYES_Image_ten
                }
            },
            {
                'Разбитое зеркало': {
                    text: The_broken_mirror_RU,
                    image: CATS_EYES_Image_ten
                }
            },
            {
                'Den krossade spegeln': {
                    text: The_broken_mirror_SE,
                    image: CATS_EYES_Image_ten,
                    sound: The_broken_mirror_SE_SOUND
                }
            },
            {
                'MURAAYADII JABTAY': {
                    text: The_broken_mirror_SO,
                    image: CATS_EYES_Image_ten
                }
            },
            {
                'እቲ ስባር መስትያት': {
                    text: The_broken_mirror_TI,
                    image: CATS_EYES_Image_ten
                }
            },
            {
                'المرآة المكسورة': {
                    text: The_broken_mirror_UAE,
                    image: CATS_EYES_Image_ten
                }
            }
        ];
        this.props.text_image(text_image_sound);
    }

    componentDidMount() {
        this.putListToObj()
    }

    render() {
        return (
            <div>
            </div>
        )
    }

}

